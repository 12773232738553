document.addEventListener('DOMContentLoaded', function () {
  let navbarPlaceholder = document.getElementById('navbar-placeholder')
  let xhr = new XMLHttpRequest()
  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        navbarPlaceholder.innerHTML = xhr.responseText
      } else {
        console.error('Error when loading the navbar: ' + xhr.status)
      }
    }
  }
  xhr.open('GET', 'components/custom-navbar.html', true)
  xhr.send()
})
